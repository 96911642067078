import React from "react";

import Card from "../containers/Card";

const Header = ({
  name,
  imageUrl,
  linkedin,
  website,
  title,
  city,
  technicalSkills,
  description,
  email,
  address,
}) => {
  return (
    <div className="header-container">
      <div>
        <h2>{title}</h2>
        <img src={imageUrl} style={{ margin: "10px", padding: "5px" }}></img>
        <div>
          <div style={{ color: "lightseagreen" }}>
            <a href={"mailto:" + email}>Email</a>
            <a target="_blank" href={linkedin}>
              Linkedin
            </a>
          </div>
          <div>{address}</div>
        </div>
        <p>
          <strong>{description}</strong>
        </p>
        <p>
          I'm also a passionate chinese chess player and have started my own
          chess platform:
          <a target="_blank" href="https://cotuong-online.com">
            https://cotuong-online.com
          </a>
          <br></br>
          Check it out and mail me for ideas and improvements! Thank you in
          advance!
        </p>
      </div>
    </div>
  );
};

export default Header;
