import React from "react";

const Skill = ({ skill }) => {
  return (
    <div className="skill">
      {skill.name}
      <div className="skill-points-container">
        <div
          className="skill-points"
          style={{
            width: skill.points * 10 + "%",
          }}
        ></div>
      </div>
    </div>
  );
};

export default Skill;
