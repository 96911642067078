import React from "react";

export default function Card({
  header,
  children,
  showBorder = true,
  tag,
  centered,
}) {
  return (
    <section
      id={tag}
      className={showBorder ? "card" : ""}
      style={{ textAlign: centered ? "center" : "" }}
    >
      {header && <h2>{header}</h2>}
      {children}
    </section>
  );
}
