import React from "react";
import Activity from "./Activity";
import Skill from "./Skill";
const Position = ({
  position,
  primaryWorkBrief,
  company,
  activities,
  skills,
  dateFrom,
  dateTo,
}) => {
  const fromTo = dateTo ? `${dateFrom} - ${dateTo}` : `${dateFrom} - 'current'`;
  return (
    <div className="position">
      <h3>
        <span>{fromTo}</span>
        <span> | {position}</span>
        {/* <span> @{company}</span> */}
      </h3>
      <h5>
        <strong>{primaryWorkBrief}</strong>
      </h5>
      <div className="activities">
        {activities &&
          activities.map((activity, index) => (
            <Activity key={index} activity={activity}></Activity>
          ))}
      </div>
      <div className="skills">
        {skills &&
          skills.map((skill, index) => (
            <Skill key={index} skill={skill}></Skill>
          ))}
      </div>
    </div>
  );
};

export default Position;
