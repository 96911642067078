import React from "react";
import TechnicalSkill from "./TechnicalSkill";

const Education = ({
  university,
  studyDegree,
  dateFrom,
  dateTo,
  thesis,
  skills,
}) => {
  return (
    <div>
      <h3>
        {studyDegree} @{university}
      </h3>
      <h5>
        <strong>
          {dateFrom} - {dateTo} | Bachelor thesis: {thesis}
        </strong>
      </h5>
      <div style={{ margin: "10px" }}>
        {skills &&
          skills.map((keyword, index) => (
            <TechnicalSkill key={index} skill={keyword}></TechnicalSkill>
          ))}
      </div>
    </div>
  );
};

export default Education;
