import React, { useState, useEffect } from "react";
import axios from "axios";
import Card from "../containers/Card";

const feedbackUrl2 = "http://localhost:3333/feedback";
const feedbackUrl = "https://baotran.de/feedback";
const Feedback = () => {
  const [feedbacks, setFeedbacks] = useState([]);
  const [newFeedback, setnewFeedback] = useState({
    author: "",
    message: "",
    id: "",
  });
  const [refresh, setRefresh] = useState({});

  useEffect(() => {
    const fetchFeedbacks = async () => {
      try {
        const feedback = await axios.get(feedbackUrl);
        setFeedbacks(feedbacks.data);
      } catch (error) {}
    };
    fetchFeedbacks();
    return () => {};
  }, [refresh]);

  const handleChange = (event) => {
    const feedback = {
      ...newFeedback,
      [event.target.name]: event.target.value,
      id: Math.random(),
    };
    setnewFeedback(feedback);
  };

  const sendMessage = async () => {
    if (!newFeedback || !newFeedback.author || !newFeedback.message) {
      return alert("Please enter your email and message !");
    }
    try {
      await axios.post(feedbackUrl, newFeedback);
      alert("Your message was sent to Bao. Please wait for feedback!");
      setnewFeedback({ author: "", message: "" });
      setRefresh({});
    } catch (error) {}
  };

  return (
    <div>
      <h3>
        I'm looking forward to your message / feedback. Nice to get to know you!
      </h3>
      <div className="new-feedback">
        <div className="form-group">
          <label htmlFor="author">Your email address:</label>
          <input
            type="email"
            className="form-control"
            id="author"
            name="author"
            placeholder="name@example.com"
            value={newFeedback.author}
            onChange={handleChange}
          ></input>
        </div>
        <div className="form-group">
          <label htmlFor="message">Your message:</label>
          <textarea
            className="form-control"
            id="message"
            rows="3"
            name="message"
            value={newFeedback.message}
            onChange={handleChange}
          ></textarea>
        </div>
        <button onClick={sendMessage}>Send message</button>
      </div>

      <div className="feedback-list">
        <Card>
          {feedbacks &&
            feedbacks.map((fb) => (
              <div className="form-group feedback" key={fb._id}>
                <strong className="author">{fb.author}</strong>
                <span style={{ marginRight: "5px" }}>:</span>
                <p className="message">{fb.message}</p>
              </div>
            ))}
        </Card>
      </div>
    </div>
  );
};
export default Feedback;
