const Resume = {
  header: {
    name: "Bao Tran",
    imageUrl: "baotran_profile_image.jpg",
    github: "",
    linkedin: "https://www.linkedin.com/in/baotran-de/",
    address: "Munich, Bavaria, Germany",
    email: "info@baotran.de",
    website: "http://www.baotran.de",
    title: "Senior Software Developer / Job seeking",
    description:
      "Hi, people. I'm a hard-worker and passionate about programming. I have experiences as fullstack developer. I like creating websites and web applications. Feel free to leave a message so i can contact you!",
  },
  technicalSkills: [
    {
      category: "Development Languages",
      keywords: [
        "C#",
        "Typescript",
        "JavaScript",
        "HTML",
        "CSS",
        "PHP",
        "SQL",
        "Java",
      ],
    },
    {
      category: "Technologies",
      keywords: [
        "Angular 2+",
        "Ionic",
        "React",
        "Asp.net Webapi",
        "NodeJs",
        "Spring Boot",
        "Apache Camel",
        "Docker",
        "Kubernetes",
      ],
    },
  ],
  positions: [
    {
      company: "Open Text Software GmbH.",
      city: "Munich",
      state: "Bavaria",
      position: "Senior software developer",
      dateFrom: "08/2021",
      dateTo: "current",
      primaryWorkBrief:
        "Design and development of customer communication management platform",
      activities: [
        "Design / Creating frontend components",
        "Working on backend to extend the communication platform",
      ],
      skills: [
        { name: "Angular", points: 9 },
        { name: "Java", points: 7 },
        { name: "Spring Boot", points: 7 },
        { name: "Apache Camel", points: 7 },
        { name: "Docker", points: 7 },
        { name: "Kubernetes", points: 7 },
      ],
    },
    {
      company: "INSCOPUS GmbH.",
      city: "Munich",
      state: "Bavaria",
      position: "IT-Consultant / Teamlead",
      dateFrom: "05/2017",
      dateTo: "12/2020",
      primaryWorkBrief:
        "Design and development of customization framework for collecting, managing and evaluating of project and company data",
      activities: [
        "Architecture and implementing of Angular, Ionic Webcomponents",
        "Co-development of backend components (Web API)",
        "Development of customer dashboards",
      ],
      skills: [
        { name: "Angular", points: 9 },
        { name: "C#", points: 7 },
        { name: "Ionic", points: 7 },
        { name: "Asp.Net Web Api", points: 7 },
      ],
    },
    {
      company: "AVL Germany Ltd.",
      city: "Munich",
      state: "Bavaria",
      position: "Software developer",
      dateFrom: "03/2016",
      dateTo: "04/2017",
      primaryWorkBrief: "Development of Test Information Management Software",
      activities: ["Several development in backend and frontend"],
      skills: [
        { name: "C#", points: 6 },
        { name: "Python, Morepath", points: 6 },
        { name: "Javascript", points: 7 },
        { name: "ReactJS", points: 7 },
      ],
    },
    {
      company: "Truma device technology Ltd.",
      city: "Munich",
      state: "Bavaria",
      position: "Software developer",
      dateFrom: "03/2014",
      dateTo: "02/2016",
      primaryWorkBrief:
        "Development of diagnosis software for heaters and air conditioners",
      activities: [
        "Creating components in Winform",
        "Creating logic for controlling devices",
      ],
      skills: [
        { name: "C#", points: 7 },
        { name: "Vb.Net", points: 7 },
        { name: "LIN-Bussystem", points: 7 },
        { name: "Winform", points: 8 },
      ],
    },
  ],
  education: {
    university: "Applied university Amberg-Weiden",
    dateFrom: "10/2009",
    dateTo: "03/2014",
    studyDegree: "Applied computer science, Bachelor degree",
    thesis: "Development of an generic i/o interface for OTX-Runtime",
    skills: ["C++", "WinApi", "OTX"],
  },
  certification: [
    {
      issuedBy: "German",
    },
    {
      issuedBy: "English",
    },
    {
      issuedBy: "Vietnamese",
    },
  ],

  projects: [
    {
      name: "Bachelor thesis",
      dateFrom: "09/2012",
      dateTo: "03/2013",
      link: "",
      teamBrief: "1-person project",
      details: [
        "Development of an generic i/o interface for OTX-Runtime",
        "Languages: C++",
        "Technologies: WinApi, OTX",
      ],
    },
  ],
};

export default Resume;
