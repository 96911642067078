import React from "react";

const TechnicalSkill = ({ skill }) => {
  return (
    <span
      className="skill"
      style={{
        backgroundColor: "lightseagreen",
        color: "white",
      }}
    >
      {skill}
    </span>
  );
};
export default TechnicalSkill;
