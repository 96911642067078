import "./App.css";
import Header from "../components/Header";
import Education from "../components/Education";
import curriculumData from "../resume-data";
import Position from "../components/Position";
import Card from "./Card";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Feedback from "../components/Feedback";
import TechnicalSkill from "../components/TechnicalSkill";
function App() {
  const { header, education, positions, technicalSkills } = curriculumData;

  return (
    <div className="App">
      <div className="header">
        <div className="banner">
          <h1>Hi everybody. From Bao Tran.</h1>
          {false && <hr className="line" />}
        </div>
      </div>
      <div className="navigation">
        <Card>
          <AnchorLink href="#header" offset="60">
            Introduction
          </AnchorLink>
          <AnchorLink href="#skills" offset="60">
            Skills
          </AnchorLink>
          <AnchorLink href="#experiences" offset="60">
            Work experiences
          </AnchorLink>
          <AnchorLink href="#education" offset="60">
            Education
          </AnchorLink>
          <AnchorLink href="#feedback" offset="60">
            Feedback
          </AnchorLink>
        </Card>
      </div>
      <div className="content-container">
        <div className="content">
          <Card header="" tag="header">
            <Header {...header}></Header>
          </Card>
          <Card header="Skills" tag="skills">
            {technicalSkills &&
              technicalSkills.map(({ category, keywords }, index) => (
                <Card showBorder={false} key={index} centered={false}>
                  {keywords &&
                    keywords.map((keyword, index) => (
                      <TechnicalSkill
                        key={index}
                        skill={keyword}
                      ></TechnicalSkill>
                    ))}
                </Card>
              ))}
          </Card>
          <Card header="Work experiences" tag="experiences">
            {positions.map((position, index) => (
              <Card key={index}>
                <Position {...position}></Position>
              </Card>
            ))}
          </Card>{" "}
          <Card header="Education" tag="education">
            <Education {...education}></Education>
          </Card>
          <Card header="Feedback" tag="feedback">
            <Feedback></Feedback>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default App;
